import { Stack } from '@chakra-ui/react';
import { ReactElement } from 'react';

import fetchContentfulData from 'api/contentful-fetch';
import { Banner, ComponentGenerator, HomeHero } from 'components/CMS';
import { ExtendedNextPageContext, ServerSideProps, UnknownObjectAny } from 'global.types';
import homepageQuery from '../graphql/queries/homepage';
import { CMSPageProps } from './[categoryPageSlug]/[pageSlug]';
import { graphQLErrorHandler } from 'utils/graphQLHandlers';

const Home = ({ content }: CMSPageProps): ReactElement => {
  return (
    <>
      {content?.pageAlertBanner && <Banner isPageAlertBanner {...content?.pageAlertBanner} />}
      <HomeHero data={content} />
      <Stack
        width="100%"
        alignItems="center"
        spacing={{ base: '10', md: '16' }}
        marginY={{ base: '5', md: '10' }}
      >
        {content?.bodyCollection?.items?.map((component: UnknownObjectAny, index: number) => (
          <ComponentGenerator
            key={`homepage-component-${index}`}
            __typename={component.__typename}
            {...component}
          />
        ))}
      </Stack>
    </>
  );
};

export async function getServerSideProps(context: ExtendedNextPageContext): ServerSideProps {
  const { preview = false } = context;
  const content = (await fetchContentfulData(
    { query: homepageQuery, variables: { preview } },
    preview
  )) as UnknownObjectAny;

  graphQLErrorHandler('homepageQuery', content?.errors, homepageQuery);

  return { props: { content: content?.data?.homepage ?? null } };
}

export default Home;
